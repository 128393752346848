<template>
  <div class="page">
	<p style="text-align: center;" class="title">
		<b>{{appName}}隐私政策概要</b>
	</p>
	<p class="title-content">我们非常注重保护用户（“您”）的个人信息及隐私，并希望通过本概要向您简洁介绍我们如何收集、使用和保护您的个人信息。
    本概要为《{{appName}}隐私政策》的附件，如您希望了解我们详细的隐私政策，请查看完整版的{{appName}}隐私政策正文。
		<br>
	</p>
	<p >
		<b><span style="font-size: 13.5pt;">一、我们如何收集和使用您的个人信息</span></b>
		<br>
	</p>
	<p style="">
		<b>
			<b>
				<u>
					<span style="font-size: 13.5pt;">
						<font>
							<img alt="Image"
                src="https://download-cos.yofish.com/yofish-gongjushiyebu/20220919193204154-kxa4jg3olks.png"
                width="22"
                height="22">注册信息</font>
					</span>
				</u>
			</b>
			<br>
		</b>
	</p>
	<p style="">
		<span style="font-size: 13.5pt;">
			<font style="">在您注册成为{{appName}}用户时，您需要至少提供手机号码以创建{{appName}}账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）。
				<br>
			</font>
		</span>
	</p>
	<p style="">
		<span style="font-size: 13.5pt;">
			<font style="">
				<br>
			</font>
		</span>
	</p>
	<p style="">
		<span style="font-size: 13.5pt;">
			<font style="">
				<b>
					<u>
						<span style="font-size: 13.5pt;">
							<font>
								<img alt="Image"
                  src="https://download-cos.yofish.com/yofish-gongjushiyebu/20220919193454505-kxa4jjf7i9i.png"
                  width="22"
                  height="22">地理位置</font>
						</span>
					</u>
				</b>
				<br>
			</font>
		</span>
	</p>
	<p style="">
		<span style="font-size: 13.5pt;">
			<font style="">
				<span style="font-size: 13.5pt;">
					<font style="">
						<b>
							<span style="font-size: 13.5pt;">
								<font style="">使用目的：</font>
							</span>
						</b>
						<span style="font-size: 13.5pt;">
							<font style="">经您授权，我们会收集您的地理位置信息，以便为您</font>
						</span>
						<span style="font-size: 13.5pt;">
							<font>完善记账信息</font>
						</span>
						<span style="font-size: 13.5pt;">
							<font style="">等。</font>
						</span>
						<br>
					</font>
				</span>
			</font>
		</span>
	</p>
	<p style="">
		<span style="font-size: 13.5pt;">
			<font style="">
				<span style="font-size: 13.5pt;">
					<font style="">
						<span style="font-size: 13.5pt;">
							<font style="">
								<b>
									<span style="font-size: 13.5pt;">
										<font>信息说明：</font>
									</span>
								</b>
								<span style="font-size: 13.5pt;">
									<font>位置信息的来源包括</font>&nbsp;IP&nbsp;地址、GPS&nbsp;以及能够提供相关信息的WLAN（如Wi-Fi）&nbsp;
                  接入点、蓝牙和基站等传感器信息。您使用服务中提供的信息中可能包含相应地理位置信息，例如您在账号资料中设置的地区信息或您上传的图片、视频中的地理标记信息。
								</span>
								<br>
							</font>
						</span>
					</font>
				</span>
			</font>
		</span>
	</p>
	<p style="">
		<span style="font-size: 13.5pt;">
			<font style="">
				<span style="font-size: 13.5pt;">
					<font style="">
						<span style="font-size: 13.5pt;">
							<font style="">
								<span style="font-size: 13.5pt;">
									<br>
								</span>
							</font>
						</span>
					</font>
				</span>
			</font>
		</span>
	</p>
	<p style="">
		<b style="color: rgb(0, 0, 0);">
			<u>
				<span style="color: rgb(27, 31, 35); font-size: 13.5pt;">
					<img alt="Image"
            src="https://download-cos.yofish.com/yofish-gongjushiyebu/20220919193740162-kxa4ji02heh.png"
            width="22"
            height="22">相机</span>
			</u>
		</b>
		<br>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>使用目的：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>经您授权，我们会收集您的相机信息，以便为您提供</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>上传照片</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>等功能。</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>信息说明：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>我们仅在您使用对应功能时收集相应信息，例如您使用</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>上传照片</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>等功能时。不同的系统显示的名称可能有所差异，如相机、摄像头等。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>您可以自行选择开启或关闭相机的授权，一旦关闭您将可能无法使用</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>上传照片</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>等功能。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>
				<br>
			</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>
				<b>
					<u>
						<span style="color: rgb(27, 31, 35); font-size: 13.5pt;">
							<font>
								<img alt="Image"
                  src="https://download-cos.yofish.com/yofish-gongjushiyebu/20220919193945383-kxa4jiv4c76.png"
                  width="22"
                  height="22">相册</font>
						</span>
					</u>
				</b>
				<br>
			</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>使用目的：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>经您授权，我们会收集您的相册信息，以便为您提供上传和保存照片、视频等功能。</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>信息说明：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>我们仅在您使用对应功能时收集相应信息，例如您使用上传和保存照片、视频等功能时。不同的系统显示的名称可能有所差异，如相册、存储权限等。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>您可以自行选择开启或关闭相册的授权，一旦关闭您将可能无法使用上传和保存图片、视频等功能。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>
				<br>
			</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>
				<b>
					<u>
						<span style="color: rgb(27, 31, 35); font-size: 13.5pt;">
							<font>
								<img alt="Image"
                  src="https://download-cos.yofish.com/yofish-gongjushiyebu/20220919194117873-kxa4s80g3x.png"
                  width="22"
                  height="22">麦克风</font>
						</span>
					</u>
				</b>
				<br>
			</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>使用目的：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>经您授权，我们会收集您的录音信息，以便为您提供语音</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>记账</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>等语音交互功能。</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>信息说明：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>我们仅在您使用对应功能时收集相应信息，通过申请麦克风权限以便获得声音，以提供对应的识别后续的交互功能。不同的系统显示的名称可能有所差异，如麦克风、录音权限等。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>您可以自行选择开启或关闭麦克风授权，一旦关闭您将可能无法使用诸如</font>
      “按住说话”、语音交互等相关功能，您也可以在系统权限中自主控制，我们绝不会私自开启您的麦克风权限，更不会非法利用非法手段进行相关信息的收集和使用，请您放心。
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<br>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<b>
				<u>
					<span style="color: rgb(27, 31, 35); font-size: 13.5pt;">
						<font>
							<img alt="Image"
                src="https://download-cos.yofish.com/yofish-gongjushiyebu/20220919194232738-kxa4jh2p3u.png"
                width="22"
                height="22">设备信息</font>
					</span>
				</u>
			</b>
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>使用目的：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>在您访问或使用我们服务的过程中，我们会收集您的设备信息，以便获取屏幕分辨率等信息进行界面展示、
        避免其他设备的异常登录、留存必要的日志信息，从而保障您的账号安全、交易安全和系统运行安全。此外，如您未登录账号，我们使用您的设备信息以便为您推送对应的信息展示。</font>
		</span>
	</p>
	<p style="text-align: justify;">
		<b>
			<span style="font-size: 13.5pt;">
				<font>信息说明：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>我们收集的设备状态包括您的设备型号，</font>
      IMEI/android ID/IDFA/IDFV/OPEN UDID/GUID/OAID、SIM卡IMSI、
      ICCID信息等设备标识符、设备MAC地址、移动应用列表、WIFI状态、电信运营商
		</span>
		<span style="font-size: 13.5pt;">
			<font>等。</font>
		</span>
		<b>
			<span style="font-size: 13.5pt;">
				<font>未经您的授权，我们不会提前获取以上信息</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>。目前多数手机终端厂商支持对设备标识符的更改，以保障您对个人信息的自主控制，具体撤回或更改的方式可参见您所使用的终端设备说明。</font>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 13.5pt;">
			<font>
				<br>
			</font>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 13.5pt;">
			<font>
				<b>
					<u>
						<span style="font-size: 13.5pt;">
							<font>
								<img alt="Image"
									src="https://download-cos.yofish.com/yofish-gongjushiyebu/20220919194436432-kxa4jdzld2t.png"
                  width="22"
                  height="22">您使用过程中主动提供的信息</font>
						</span>
					</u>
				</b>
				<br>
			</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>使用目的：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>在您使用我们提供的产品</font>/服务过程中，我们根据您使用的特定场景可能会收集您提供的个人信息，以实现您所需要使用的功能。
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>信息说明：</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>我们会根据具体的场景请求您提供对应的信息并会严格依法保护您的个人信息，但如果您不提供该等信息，不会影响您使用我们产品</font>/服务的基本功能。
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>除法律另有规定外，我们</font>
		</span>
		<b>
			<span style="font-size: 13.5pt;">
				<font>仅会在实现目的所必需的最短时间内留存</font>
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>您的相关个人信息。如您希望详细了解我们所收集的具体各项个人信息及对应的场景，请查阅概要后的隐私政策正文。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>对于我们收集的个人信息清单明细，您可以查阅以下</font>
		</span>
		<a :href="`https://jz-h5.yofish.com/information/user/home?appName=${appName}&appType=${appType}`" target="_self">
			<span style="color: rgb(0, 0, 255); font-size: 15pt;">
				<font>{{appName}}</font>
			</span>
			<span style="color: rgb(0, 0, 255); font-size: 15pt;">App收集个人信息清单</span>
		</a>
		<span style="font-size: 13.5pt;">
			<font>。</font>
		</span>
	</p>
	<p style="">
		<br>
	</p>
	<p style="">
		<b>
			<span style="font-size: 13.5pt;">
				<font>二、我们如何共享您的信息</font>
			</span>
		</b>
		<br>
	</p>
	<p style="">
		<span style="font-size: 13.5pt;">
			<font>请您知悉，</font>
		</span>
		<b>
			<span style="font-size: 13.5pt;">
				<font>我们为您所提供的产品</font>/服务大多是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息
			</span>
		</b>
		<span style="font-size: 13.5pt;">
			<font>。目前，除再次获得您明确同意或授权外，此类共享或委托处理涉及的主要情形包括：
        根据行政、司法等有权机关的要求提供必要信息；为提供账号一致性服务与关联公司共享必要信息；为</font>
		</span>
		<br>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>提供如第三方支付等服务与有关合作商、第三方商家、支付机构等共享有关的必要信息等。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</font>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 13.5pt;">
			<font>对于我们使用的第三方软件工具开发包（</font>“SDK”），以及与第三方共享的个人信息，您可以点击
		</span>
		<a :href="`https://jz-h5.yofish.com/information/bm?appName=${appName}&appType=${appType}`">
			<span style="color: rgb(0, 0, 255); font-size: 15pt;">
				<font>{{appName}}</font>
			</span>
			<span style="color: rgb(0, 0, 255); font-size: 15pt;">
				<font>与第三方共享个人信息清单</font>
			</span>
		</a>
		<span style="font-size: 13.5pt;">
			<font>查阅。</font>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 13.5pt;">
			<font>
				<br>
			</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>三、您如何管理您的信息</font>
			</span>
		</b>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>您有权管理您的个人信息，包括查询、更正和删除您的账号信息、搜索记录、
        评论信息，改变您授权同意的范围或撤回授权，以及注销您的账号。您可以通过我们的</font>App中提供的功能设置进行上述操作。
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>如果您无法通过上述方式进行管理，您可以通过我们以下提供的联系方式联系我们，我们将在</font>15天内回复您的要求。
		</span>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<br>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>四、我们如何做好未成年人保护</font>
			</span>
		</b>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>我们非常重视未成年人个人信息的保护。由于我们的网站和服务主要面向成人，</font>
		</span>
		<b>
			<span style="font-size: 13.5pt;">
				<font>如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，
          并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。请您和您的监护人阅读我们专门制定的</font>
			</span>
		</b>
		<a :href="`https://jz-h5.yofish.com/information/pguide?appName=${appName}&appType=${appType}`" target="_self">
			<b>
				<span style="color: rgb(0, 0, 255); font-size: 15pt;">
					<font>《未成年人隐私政策》</font>
				</span>
			</b>
		</a>
		<b>
			<span style="font-size: 13.5pt;">
				<font>，并在指导下使用我们的服务。</font>
			</span>
		</b>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 13.5pt;">
			<font>
				<br>
			</font>
		</span>
	</p>
	<p>
		<b>
			<span style="font-size: 13.5pt;">
				<font>五、您如何联系我们</font>
			</span>
		</b>
	</p>
	<p>
		<span style="font-size: 13.5pt;">
			<font>您可以通过</font>
		</span>
		<span style="font-size: 13.5pt;">
			<font>{{appName}}</font>
		</span>
		<span style="font-size: 13.5pt;">App上提供的在线联系方式与我们联系</span>
		<span style="font-size: 13.5pt;">
			<font>，我们将在</font>15天内答复您的请求
		</span>
		<span style="font-size: 13.5pt;">
			<font>。</font>
		</span>
	</p>
	<p style="text-align: justify;">
		<span style="font-size: 13.5pt;">
			<font>
				<br>
			</font>
		</span>
	</p>
</div>
</template>
<script>
export default {
  name: 'Privacy',
  computed: {
    appName() {
      return this.$route.query?.appName || '有鱼记账';
    },
    appType() {
      return this.$route.query?.appType || 1;
    },
  },
  mounted() {
    document.title = `${this.appName}隐私政策概要`;
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 30px;
  padding: 80px 0;
}
.title-content {
  font-size: 28px;
  margin-bottom: 20px;
}
.page {
  padding: 20px;
}
</style>
